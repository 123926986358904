import React, { useEffect, useState } from 'react';
import {IonContent, IonFooter, IonIcon, IonItem, IonMenu} from '@ionic/react';
import './index.scss';
import './responsive.scss';
import { arrowUndo, search, settings } from 'ionicons/icons';
import { logOut } from '../../../data/persistent/persistent.actions';
import { clearLoadingCount } from '../../../data/session/session.actions';
import { useLocation } from 'react-router';
import { connect } from '../../../data/connect';

interface HomeMenuLinkProps {
    url: string;
    className?: string;
    exact?: boolean;
}

const HomeMenuLink: React.FC<HomeMenuLinkProps> = ({ url, className, exact, children }) => {
    const location = useLocation();

    let active = exact ? location.pathname === url : location.pathname.includes(url);

    return (
        <IonItem lines={'none'} routerLink={url} className={(active ? 'active ' : '') + className ?? ''}>
            {children}
        </IonItem>
    );
};

interface DispatchProps {
    logOut: typeof logOut;
    clearLoadingCount: typeof clearLoadingCount;
}

interface HomeDesktopMenuProps extends DispatchProps { }

const HomeDesktopMenuLeft: React.FC<HomeDesktopMenuProps> = ({ logOut, clearLoadingCount }) => {
    const location = useLocation();
    const [lastLoadedFeed, setLastLoadedFeed] = useState('/home/dashboard/feed');

    /**
     * Logs out the user from the app properly
     */
    const handleLogOut = () => {
        logOut();
        clearLoadingCount();
        window.location.pathname = '/welcome/splash';
    };

    useEffect(() => {
        if (location.pathname.startsWith('/home/dashboard/feed')) {
            setLastLoadedFeed(location.pathname);
        }
    }, [location.pathname]);

    return (
        <IonMenu className={'home-desktop-menu start'} contentId={'home'} side={"start"} swipeGesture={false}>

            <img alt={"Geddit Local"} className="desktop-logo" src={"/assets/web-splash/geddit-local-logo.svg"}/>

            <IonContent>
                <HomeMenuLink url={lastLoadedFeed}>
                    <IonIcon src={'/assets/app-icons/logo.svg'} /> Feed
                </HomeMenuLink>
                <HomeMenuLink url={'/home/dashboard/explore'}>
                    <IonIcon icon={search} /> Explore
                </HomeMenuLink>
                <HomeMenuLink url={'/home/dashboard/post-creator'}>
                    <IonIcon src={'/assets/app-icons/add.svg'} /> Post
                </HomeMenuLink>
                <HomeMenuLink url={`/home/dashboard/albums`}>
                    <IonIcon src={'/assets/app-icons/bookmarks.svg'} /> Albums
                </HomeMenuLink>
                <HomeMenuLink url={'/home/dashboard/account'} exact>
                    <IonIcon src={'/assets/app-icons/account.svg'} /> Profile
                </HomeMenuLink>
            </IonContent>
            <IonFooter>
                <HomeMenuLink url={'/home/dashboard/account/settings'}>
                    <IonIcon icon={settings} /> Settings
                </HomeMenuLink>
                <IonItem routerLink={'#'} lines={'none'} onClick={handleLogOut}>
                    <IonIcon icon={arrowUndo} /> Log Out
                </IonItem>
            </IonFooter>
        </IonMenu>

    );
};

const ConnectedHomeDesktopMenu = connect<{}, {}, DispatchProps>({
    mapDispatchToProps: {
        logOut,
        clearLoadingCount,
    },
    component: HomeDesktopMenuLeft,
});

export default ConnectedHomeDesktopMenu;
