import React, {useState} from 'react';
import Post from '../../../models/post/post';
import Location from '../../../models/location/location';
import './index.scss';
import {IonButton, IonIcon} from '@ionic/react';
import {convertHexColorToBrightness, ellipsisText} from '../../../util/strings';
import PostResponse from '../../../models/post/post-response';
import {ellipsisHorizontal, heart, heartOutline, bookmark, bookmarkOutline} from 'ionicons/icons';
import PostEllipsesMenu from '../PostEllipsesMenu';
import PostComments, {PostCommentInteractions} from '../PostComments';
import {Share} from '@capacitor/share';
import User from '../../../models/user/user';
import {UserFollowsContextState} from '../../../contexts/UserFollowsContext';

interface PostActionCalloutProps {
    callout: number|string,
    onClick: () => void,
    className?: string,
    icon?: string,
    src?: string,
}

const PostActionCallout: React.FC<PostActionCalloutProps> = ({callout, onClick, icon, src, className}) => {

    return (
        <div className={'post-action-callout ' + (className ?? '')} onClick={onClick}>
            <IonIcon icon={icon} src={src}/>
            <p>{callout}</p>
        </div>
    )
}

export interface PostInteractions {
    onFollow: (id: number) => void,
    onUnfollow?: () => void,
    onLikeToggled: () => void,
    onShare: () => void,
    onSaved: () => void,
    onButtonClicked: () => void,
    onReport: () => void,
    onRemove: () => void,
}

interface PostActionsProps {
    post: Post,
    postResponse?: PostResponse,
    location?: Location,
    onLikeClicked?: () => void,
    interactions?: PostInteractions,
    commenting?: PostCommentInteractions,
    onOptionsClickedOverride?: () => void,
    userFollowsContext?: UserFollowsContextState,
}

const PostActions: React.FC<PostActionsProps> = ({post, postResponse, location, interactions, onLikeClicked, onOptionsClickedOverride, commenting, userFollowsContext}) => {
    const [optionsShowing, setOptionsShowing] = useState(false);
    const [viewingComments, setViewingComments] = useState(false);

    const buttonClicked = (url: string) => {
        interactions?.onButtonClicked();
        window.open((url.startsWith('http') ? '' : 'http://') + url);
    }

    const share = () => {

        let subject = '';
        let text;
        if (post.publisher_type === 'organization') {
            subject = location ?
                location.name + " on Geddit Local" : post.title!;
            const message = location ?
                location.name + " is posting on Geddit Local: " + post.article_content : post.article_content!;
            text = ellipsisText(message ? message : '', 75, '…');
        } else {
            const user = post.publisher as User
            text = user.full_name + ' is posting' + (location ? ' about ' + location.name : '') + ' on Geddit Local:';
        }

        Share.share({
            title: subject,
            text: text + " https://app.gedditlocal.com/post/" + post.id,
        })
        interactions?.onShare()
    }

    const brightness = convertHexColorToBrightness( post.button_color ?? '#fe9024')
    const buttonTextColor = brightness > 177.5 ? '#000000' : '#FFFFFF'

    const hasLiked = postResponse && postResponse.liked;
    const hasSaved = postResponse && postResponse.saved;
    const hasButton = (post.button_label && post.url)

    return (
        <div className={'post-actions-wrapper'}>
            {hasButton &&
                <IonButton
                    onClick={() => buttonClicked(post.url!)}
                    style={{'--background': post.button_color, '--color': buttonTextColor}}
                >
                    {post.button_label}
                </IonButton>
            }
            <div className={'post-actions'}>
                <PostActionCallout
                    callout={post.like_count ?? 0}
                    onClick={() => onLikeClicked ? onLikeClicked() : interactions?.onLikeToggled()}
                    icon={hasLiked ? heart: heartOutline}
                />
                {/*{post.comments_allowed &&*/}
                {/*    <PostActionCallout*/}
                {/*        callout={post.comment_count ?? 0}*/}
                {/*        onClick={() => commenting && setViewingComments(true)}*/}
                {/*        icon={chatbubbleEllipses}*/}
                {/*    />*/}
                {/*}*/}
                <PostActionCallout
                    callout={"share"}
                    className={"share"}
                    onClick={share}
                    src={'/assets/app-icons/share.svg'}
                />
                <PostActionCallout
                    className={'save'}
                    callout={"save"}
                    onClick={() => interactions?.onSaved()}
                    icon={hasSaved ? bookmark : bookmarkOutline}
                />
                {interactions &&
                    <IonIcon
                        icon={ellipsisHorizontal}
                        onClick={() => onOptionsClickedOverride ? onOptionsClickedOverride() : setOptionsShowing(true)}
                    />
                }
                <PostEllipsesMenu
                    open={optionsShowing}
                    onDismissed={() => setOptionsShowing(false)}
                    post={post}
                    location={location}
                    onShare={() => share()}
                    onFollow={id => interactions?.onFollow(id)}
                    onReport={() => interactions?.onReport()}
                    onRemove={() => interactions?.onRemove()}
                    userFollowsContext={userFollowsContext}
                />
                {commenting &&
                    <PostComments
                        post={post}
                        isOpen={viewingComments}
                        onDismissed={() => setViewingComments(false)}
                        interactions={commenting}
                    />
                }
            </div>
        </div>
    )
}

export default PostActions;
