import React, {useEffect, useRef, useState} from 'react'
import {
	IonTabButton,
	IonRouterOutlet,
	IonTabBar,
	IonTabs,
	IonContent,
	IonModal,
	IonButton,
	IonPage, IonIcon, createAnimation,
} from '@ionic/react';
import './index.scss';
import {connect} from '../../../data/connect';
import {useHistory} from 'react-router-dom';
import {Route} from 'react-router';
import Following from './Following';
import Nearby from './Nearby';
import {setHasSeenIntroductionTutorial} from '../../../data/persistent/persistent.actions';
import MeContextProvider, {MeContext, MeContextStateConsumer} from '../../../contexts/MeContext';
import NewsFeedSettings from "../../../components/NewsFeed/NewsFeedSettings";
import {
	add,
	arrowBackCircleOutline,
	arrowForwardCircleOutline
} from "ionicons/icons";
import Category from "./Category";

interface FeedTabsProps {
	meContext: MeContextStateConsumer
	onScroll: (isScrollDown: boolean) => void
}

const FeedTabs: React.FC<FeedTabsProps> = ({onScroll, meContext}) => {

	const [feedTabsBarScrollPos, setFeedTabsBarScrollPos] = useState(0)
	const [canScrollLeft, setCanScrollLeft] = useState(false)
	const [canScrollRight, setCanScrollRight] = useState(false)
	const history = useHistory();

	const tabBarEl = useRef<HTMLIonTabBarElement | null>(null);

	const goToTopics = () => {
		history.push('/home/dashboard/topics')
	}

	const scroll = (direction: 1|-1) => {
		const topicsBar = document.getElementById('topics-bar')
		if (topicsBar) {
			let newScrollPos = feedTabsBarScrollPos + (topicsBar.offsetWidth * .75 * direction)
			let maxScroll = topicsBar.scrollWidth - topicsBar.clientWidth // determine an actual max scroll

			if (newScrollPos < 0) {
				newScrollPos = 0
			}

			if (newScrollPos > maxScroll) {
				newScrollPos = maxScroll
			}
			Array.from(topicsBar.children).forEach(child => {
				if (!child.classList.contains('traverse')) {
					createAnimation()
						.addElement(child)
						.duration(300)
						.iterations(1)
						.fromTo('right', feedTabsBarScrollPos + 'px', newScrollPos + 'px').play()
				}
			})


			setFeedTabsBarScrollPos(newScrollPos)
			setCanScrollLeft(newScrollPos !== 0)
			setCanScrollRight(newScrollPos !== maxScroll)
		}
	}

	const initializeTopicsArrows = (reset: boolean) => {
		const topicsBar = document.getElementById('topics-bar')
		if (topicsBar) {
			setCanScrollRight(topicsBar.scrollWidth - topicsBar.clientWidth > 0)
			setCanScrollLeft(false)

			if (reset) {
				setFeedTabsBarScrollPos(0)
				Array.from(topicsBar.children).forEach(child => {
					if (!child.classList.contains('traverse')) {
						createAnimation()
							.addElement(child)
							.duration(1)
							.iterations(1)
							.fromTo('right', feedTabsBarScrollPos + 'px', '0px').play()
					}
				})
			}
		}
	}

	useEffect(() => {
		// Nested tabs break detecting the proper url sometimes,
		// so putting the redirect on a delay allows for the component to mount,
		// and receive the events it needs to as opposed to the parent tab component
		setTimeout(() => {
			if (window.location.pathname === "/home/dashboard/feed") {
				history.push('/home/dashboard/feed/nearby')
			}
		}, 5)

		// resize events for scrollable tab bar
		const handleResize = () => {
			initializeTopicsArrows(true)
		};

		window.addEventListener('resize', handleResize);

		// Clean up the event listener on component unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		// After render, set visibility property of each arrow
		setTimeout(() => initializeTopicsArrows(false))
	}, [tabBarEl, tabBarEl.current])

	return (
		<IonTabs className={'feed-tabs'}>
			<IonRouterOutlet>
				<Route path={`/home/dashboard/feed/nearby`} render={() => <Nearby onScroll={onScroll}/>}/>
				<Route path={`/home/dashboard/feed/following`} render={() => <Following onScroll={onScroll}/>}/>
				{meContext.me.followed_categories?.map(i => (
					<Route key={i.id} path={`/home/dashboard/feed/category/${i.id}`} render={() => <Category categoryId={i.id!} onScroll={onScroll}/>}/>
				))}
			</IonRouterOutlet>
			<IonTabBar ref={tabBarEl} id={'topics-bar'} slot="top" className={'feed-tabs-bar'}>
				<IonTabButton className={"backward traverse" + (canScrollLeft ? ' visible' : '')}>
					<IonIcon onClick={() => scroll(-1)} icon={arrowBackCircleOutline}/>
				</IonTabButton>
				<IonTabButton>
					<IonIcon onClick={goToTopics} icon={add}/>
				</IonTabButton>
				<IonTabButton tab='nearby' href={`/home/dashboard/feed/nearby`}>
					For you
				</IonTabButton>
				<IonTabButton tab='following' href={`/home/dashboard/feed/following`}>
					Following
				</IonTabButton>
				{meContext.me.followed_categories?.map(i => (
					<IonTabButton key={i.id} tab={`category-${i.id}`} href={`/home/dashboard/feed/category/${i.id}`}>
						{i.name}
					</IonTabButton>
				))}
				<IonTabButton className={"forward traverse" + (canScrollRight ? ' visible' : '')}>
					<IonIcon onClick={() => scroll(1)} icon={arrowForwardCircleOutline}/>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	)
}

interface StateProps {
	hasSeenIntroductionTutorial: boolean;
}

interface DispatchProps {
	setHasSeenIntroductionTutorial: typeof setHasSeenIntroductionTutorial,
}

interface FeedProps extends StateProps, DispatchProps {}

const FeedPage: React.FC<FeedProps> = ({setHasSeenIntroductionTutorial, hasSeenIntroductionTutorial, ...props}) => {
	const [settingsHidden, setSettingsHidden] = useState(false)

	const [introductionModalShowing, setIntroductionModalShowing] = useState(!hasSeenIntroductionTutorial);

	const introductionModalDismissed = () => {
		setHasSeenIntroductionTutorial(true);
		setIntroductionModalShowing(false);
	}

	const feedScrolled = (isScrollDown: boolean) => {
		if (settingsHidden !== isScrollDown) {
			setSettingsHidden(isScrollDown)
		}
	}
	return (
		<IonPage className={'feed-page' + (settingsHidden ? ' settings-hidden' : '')}>
			<MeContextProvider>
				<MeContext.Consumer>
					{meContext =>
						<IonContent className={'home-content'} id={'home'} scrollY={false}>
							<NewsFeedSettings isVisible={!settingsHidden}/>
							<IonRouterOutlet>
								<Route path={`/home/dashboard/feed`} render={() =>
									<FeedTabs meContext={meContext} onScroll={feedScrolled} {...props}/>
								}/>
								<Route path={`/home/dashboard/feed/*`} render={() =>
									<FeedTabs meContext={meContext} onScroll={feedScrolled} {...props}/>
								}/>
							</IonRouterOutlet>
							<IonModal
								className={'introduction-modal'}
								onDidDismiss={introductionModalDismissed}
								isOpen={introductionModalShowing}
							>
								<div className={'introduction-modal-content'}>
									<div className={'profile-name'}>
										{meContext.me.first_name.slice(0, 1) + " " + meContext.me.last_name.slice(0, 1)}
									</div>
									<h4>Time to find things to do</h4>
									<p>Get new ideas from local businesses and guides in the community around you!</p>
									<IonButton onClick={introductionModalDismissed}>Start scrolling</IonButton>
								</div>
							</IonModal>

						</IonContent>
					}
				</MeContext.Consumer>
			</MeContextProvider>
		</IonPage>
	);
}

export default connect<{ }, StateProps, {  }>({
	mapStateToProps: (state) => ({
		hasSeenIntroductionTutorial: state.persistent.hasSeenIntroductionTutorial,
	}),
	mapDispatchToProps: ({
		setHasSeenIntroductionTutorial,
	}),
	component: FeedPage
});
