import React, {ReactNode, useState} from 'react';
import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonTitle,
	IonToolbar
} from '@ionic/react';
import './index.scss';
import LoadingIndicatorComponent from '../LoadingIndicator';
import {ellipsisHorizontal} from "ionicons/icons";


interface EllipsisMenuOption {
	text: string,
	handler: () => void,
	color?: string,
	icon?: string
}

interface Props {
	className?: string,
	hideBackButton?: boolean
	onBackPressed?: () => void,
	children?: ReactNode
	iconType?: any
	blackHeader?: boolean
	orangeHeader?: boolean
	hideLoading?: boolean,
	rightIcon?: {
		icon: string,
		handler: () => void,
		className?: string,
		mobileOnly?: boolean,
		desktopOnly?: boolean,
	}
	rightButton?: {
		text: string,
		handler: () => void,
	}
	ellipsisMenu?: EllipsisMenuOption[]
}

const LoggedInHeaderComponent: React.FC<Props> = ({ className, iconType, onBackPressed, blackHeader, orangeHeader, hideLoading, hideBackButton, rightIcon, rightButton, ellipsisMenu, children }) => {
	const headerClasses = (orangeHeader ? "orange-header " : '') + (blackHeader ? "black-header " : '') + className

	const [isEllipsisMenuOpen, setIsEllipsisMenuOpen] = useState(false)

	const ellipsisMenuClicked = (menuOption: EllipsisMenuOption) => {
		setIsEllipsisMenuOpen(false)
		menuOption.handler()
	}

	return (
		<IonHeader className={"logged-in-header ion-no-border " + headerClasses}>
			{ellipsisMenu && isEllipsisMenuOpen &&
				<IonList className={'ellipsis-menu'}>
					{ellipsisMenu.map(item =>
						<IonItem
							key={item.text}
							onClick={() => ellipsisMenuClicked(item)}
						>
							<IonLabel color={item.color}>{item.text}</IonLabel>
							{!!item.icon && <IonIcon slot={'end'} icon={item.icon} color={item.color}/>}
						</IonItem>
					)}
				</IonList>
			}
			<IonToolbar>
				<IonButtons slot="start">
					{!hideBackButton && (onBackPressed ?
						<IonButton onClick={onBackPressed} fill={'clear'}>
							<IonIcon icon={iconType} />
						</IonButton> :
						<IonBackButton defaultHref={'/home'} icon={iconType} text='' />
					)}
				</IonButtons>
				<IonTitle>{children}</IonTitle>
				<IonButtons slot={'end'}>
					{rightIcon ?
						<IonIcon
							icon={rightIcon.icon}
							className={rightIcon.className + (rightIcon.mobileOnly ? ' hide-in-desktop' : '') + (rightIcon.desktopOnly ? ' hide-in-mobile' : '')}
							onClick={rightIcon.handler}
						/>
						: !hideLoading && <LoadingIndicatorComponent/>
					}
					{!!rightButton &&
						<IonButton
							className={'right-button'}
							onClick={rightButton.handler}
							fill={"clear"}
							color={"medium"}
						>
							{rightButton.text}
						</IonButton>
					}
					{!!ellipsisMenu &&
						<IonIcon
							icon={ellipsisHorizontal}
							color={"medium"}
							onClick={() => setIsEllipsisMenuOpen(!isEllipsisMenuOpen)}
						/>
					}
				</IonButtons>
			</IonToolbar>
		</IonHeader>
	)
}

export default LoggedInHeaderComponent
