import React, {useState} from 'react'
import {IonContent, IonPage, IonToast, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import './index.scss';
import './responsive.scss';
import MeContextProvider, {MeContext, MeContextStateConsumer} from '../../../contexts/MeContext';
import {PostContext, PostContextConsumerState, PostContextProvider} from '../../../contexts/PostContext';
import Post from '../../../models/post/post';
import Location from '../../../models/location/location';
import {
	UserPostResponsesContextProvider,
	UserPostResponsesContext,
	UserPostResponsesContextState
} from "../../../contexts/UserPostResponsesContext";
import User from "../../../models/user/user";
import PostResponse from "../../../models/post/post-response";
import {useHistory} from 'react-router-dom';
import {chevronBack} from "ionicons/icons";
import {useLocation, useParams} from 'react-router';
import InteractivePost from '../../../components/Posts/InteractivePost';
import SeenPostsContextProvider, {SeenPostsContext, SeenPostsContextConsumerState} from '../../../contexts/feed/SeenPostsContext';
import {UserFollowsContext, UserFollowsContextProvider} from '../../../contexts/UserFollowsContext';

interface PostPageContentProps {
	post: Post,
	me: User,
	url: string
	userPostResponseContextConsumer: UserPostResponsesContextState
	setPost: (post: Post) => void,
	inFocus: boolean,
}

const PostPageContent: React.FC<PostPageContentProps> = ({post, setPost, me, url, inFocus, userPostResponseContextConsumer}) => {

	const navigate = useHistory();
	const [postRemovedToastShowing, setPostRemovedToastShowing] = useState(false);

	const getLocation = (post: Post): Location|undefined => {
		return post.locations && post.locations.length > 0 ? post.locations[0] : undefined;
	}

	const updateResponse = (postResponse: PostResponse): void => {
		if (!postResponse.reported) {
			userPostResponseContextConsumer.addModel(postResponse);
		} else {
			userPostResponseContextConsumer.removeModel(postResponse);
			navigate.goBack();
		}
	};

	const postRemoved = () => {
		setPostRemovedToastShowing(true);
		setTimeout(navigate.goBack, 1000);
	}

	return (
		<IonContent className={'post-details-content-wrapper ion-no-padding'}>
			<IonToast
				isOpen={postRemovedToastShowing}
				onDidDismiss={() => setPostRemovedToastShowing(false)}
				message={'This post has been removed. It will no longer be shown to anyone besides the person who published it.'}
				duration={2000}
			/>
			<UserFollowsContextProvider userId={me.id!}>
				<UserFollowsContext.Consumer>
					{userFollowsContext =>
						<InteractivePost
							post={post}
							onPostMetaChanged={setPost}
							locationPageUrlRoot={'/home/dashboard'}
							userPageUrlRoot={'/home/dashboard'}
							loggedInUser={me}
							location={getLocation(post)}
							onPostResponseChanged={postResponse => updateResponse({...postResponse, post: post})}
							inFocus={inFocus}
							hasViewed
							onPostRemoved={postRemoved}
							userFollowsContext={userFollowsContext}
						/>
					}
				</UserFollowsContext.Consumer>
			</UserFollowsContextProvider>
		</IonContent>
	)
}

interface SeenPostsReadyProps extends PostLoadedProps {
	seenPostsContext: SeenPostsContextConsumerState,
	meContext: MeContextStateConsumer,
}

const SeenPostsReady: React.FC<SeenPostsReadyProps> = ({meContext, postContext, seenPostsContext, inFocus}) => {

	const location = useLocation()


	const setPost = (post: Post) => {
		postContext.setPost(post);
		seenPostsContext.setPost(post);
	}

	return (
		<UserPostResponsesContextProvider userId={meContext.me.id!}>
			<UserPostResponsesContext.Consumer>
				{userPostResponseContextConsumer =>
					<PostPageContent
						key={postContext.post.id}
						post={postContext.post}
						setPost={setPost}
						me={meContext.me}
						url={location.pathname}
						userPostResponseContextConsumer={userPostResponseContextConsumer}
						inFocus={inFocus}
					/>
				}
			</UserPostResponsesContext.Consumer>
		</UserPostResponsesContextProvider>
	)
}

interface PostLoadedProps {
	inFocus: boolean,
	postContext: PostContextConsumerState,
}

const PostLoaded: React.FC<PostLoadedProps> = (props) => {


	return (
		<MeContextProvider>
			<MeContext.Consumer>
				{meContext =>
					<SeenPostsContextProvider>
						<SeenPostsContext.Consumer>
							{seenPostsContext =>
								<SeenPostsReady
									seenPostsContext={seenPostsContext}
									meContext={meContext}
									{...props}
								/>
							}
						</SeenPostsContext.Consumer>
					</SeenPostsContextProvider>
				}
			</MeContext.Consumer>
		</MeContextProvider>
	)
}

type RouteParams = {
	postId: string,
}

const PostPage: React.FC = () => {
	const [inFocus, setInFocus] = useState(false);

	const {postId} = useParams<RouteParams>();

	useIonViewWillEnter(() => {
		setInFocus(true);
	})
	useIonViewWillLeave(() => {
		setInFocus(false);
	})

	return (
		<IonPage className={'post-page'}>
			<LoggedInHeaderComponent iconType={chevronBack}/>
			<IonContent scrollY={false}>
				<PostContextProvider postId={parseInt(postId!)}>
					<PostContext.Consumer>
						{postContext =>
							<PostLoaded
								inFocus={inFocus}
								postContext={postContext}
							/>
						}
					</PostContext.Consumer>
				</PostContextProvider>
			</IonContent>
		</IonPage>
	)
}

export default PostPage
