import React, {useEffect, useState} from 'react';
import Post, {PublisherEndpointModel} from '../../../models/post/post';
import Location from '../../../models/location/location';
import './index.scss';
import PostResponse, {
    defaultPostResponse, isPostResponseDirty,
    mergePostResponses,
    postResponseToServerSubmission
} from '../../../models/post/post-response';
import CanParticipateInForum from '../../../models/can-participate-in-forum';
import Thread from '../../../models/user/thread';
import Message from '../../../models/user/message';
import {PostInteractions} from '../PostActions';
import PostDetails from '../PostDetails';
import User from '../../../models/user/user';
import UserPostRequests from '../../../services/requests/UserPostRequests';
import {PostCommentInteractions} from '../PostComments';
import {connect} from '../../../data/connect';
import {setNotificationPostInit, setSharedPostInit} from '../../../data/session/session.actions';
import PostManagementRequests from '../../../services/requests/PostManagementRequests';
import moment from 'moment';
import {UserFollowsContextState} from '../../../contexts/UserFollowsContext';
import AlbumModal from "../../Albums/AlbumModal";
import {
    CollectionItemsContext,
    CollectionItemsContextProvider,
    CollectionItemsContextState
} from "../../../contexts/CollectionItemsContext";
import {
    UserCollectionsContext,
    UserCollectionsContextProvider,
    UserCollectionsContextState
} from "../../../contexts/UserCollectionsContext";
import {IonToast} from "@ionic/react";
import {PostResponseContext, PostResponseContextConsumerState, PostResponseContextProvider} from "../../../contexts/PostResponseContet";

interface InteractivePostLoadedProps extends InteractivePostProps {
    collectionItemsContextState: CollectionItemsContextState,
    userCollectionsContext: UserCollectionsContextState,
    postResponseContext: PostResponseContextConsumerState,
}

const InteractivePostLoaded: React.FC<InteractivePostLoadedProps> = ({post, onPostMetaChanged, postResponseContext, hasViewed, commenting, onUnfollow, loggedInUser, onPostResponseChanged, onLikeClicked, sharedPostInit, onPostRemoved, notificationPostInit, setSharedPostInit, setNotificationPostInit, collectionItemsContextState, userCollectionsContext, userFollowsContext, location, ...rest}) => {

    const [toastMessage, setToastMessage] = useState("");
    const [saveModalShowing, setSaveModalShowing] = useState(false);

    const [originalPost, setOriginalPost] = useState(post);
    const [searchCompleted, setSearchCompleted] = useState(false);
    const [postResponse, setPostResponse] = useState(
        postResponseContext.postResponse ? {...postResponseContext.postResponse} : {...defaultPostResponse}
    )

    const uploadPostResponse = (newData: PostResponse) => {
        setPostResponse({
            ...newData,
        })

        if (loggedInUser.id && (!postResponse.id || postResponseContext.postResponse && isPostResponseDirty(postResponseContext.postResponse, newData))) {

            if (sharedPostInit) {
                newData.share_clicked = sharedPostInit;
                setSharedPostInit(false);
            }
            if (notificationPostInit) {
                newData.notification_clicked = notificationPostInit;
                setNotificationPostInit(false);
            }

            const submitData = postResponseToServerSubmission(newData);

            const creating = !postResponse.id;
            (creating ?
                    UserPostRequests.reportPostResponse(post, submitData) :
                    UserPostRequests.updatePostResponse(postResponse, submitData)
            ).then(updated => {

                const constructed = {...updated, post};
                if (onPostResponseChanged) {
                    onPostResponseChanged(constructed)
                }
                postResponseContext.setPostResponse({
                    ...constructed,
                })
            }).catch(console.error)
        }
    }

    const updatePostResponse = (newData: PostResponse) => {
        if (hasViewed && searchCompleted) {
            uploadPostResponse(newData);
        }
    }

    const actions: PostInteractions = {
        onButtonClicked: () => {
            if (!postResponse.clicked) {
                onPostMetaChanged({
                    ...post,
                    click_count: post.click_count + 1,
                })
                updatePostResponse({
                    ...postResponse,
                    clicked: true,
                })
            }
        },
        onSaved: (saveConfirmed = false) => {
            if (!postResponse.saved && !saveConfirmed && !collectionItemsContextState.isInCollection(post)) {
                setSaveModalShowing(true)
            }
            else {
                const newState = !postResponse.saved
                const originalState = postResponseContext.postResponse ? postResponseContext.postResponse.saved : false;
                const change = originalState == newState ? 0 :
                    (newState ? 1 : -1)
                updatePostResponse({
                    ...postResponse,
                    saved: newState,
                })
                if (!newState) {
                    const foundItem = collectionItemsContextState.removePost(post)
                    let toastMessage = "Removed from your albums."

                    if (foundItem) {
                        const collectionId = collectionItemsContextState.collectionId!
                        const collection = userCollectionsContext.loadedData.find(i => i.id == collectionId);
                        if (collection && collection.name) {
                            toastMessage = "Removed from " + collection.name;
                        }
                        if (collection) {
                            collection.collection_items_count = collection.collection_items_count - 1
                            userCollectionsContext.addModel(collection)
                        }
                    }

                    setToastMessage(toastMessage);
                } else {
                    collectionItemsContextState.keepPost(post)
                }
                onPostMetaChanged({
                    ...post,
                    save_count: originalPost.save_count + change,
                })
            }
        },
        onFollow: (followsId: number) => {
            updatePostResponse({
                ...postResponse,
                follows_id: followsId,
            })
        },
        onUnfollow: onUnfollow,
        onLikeToggled: () => {
            const newState = !postResponse.liked
            const originalState = postResponseContext.postResponse ? postResponseContext.postResponse.liked : false;
            const change = originalState == newState ? 0 :
                (newState ? 1 : -1)
            updatePostResponse({
                ...postResponse,
                liked: newState,
            })
            onPostMetaChanged({
                ...post,
                like_count: (post.like_count ?? 0) + change,
            })
        },
        onShare: () => {
            if (!postResponse.shared) {
                onPostMetaChanged({
                    ...post,
                    share_count: post.share_count + 1,
                })
                updatePostResponse({
                    ...postResponse,
                    shared: true,
                })
            }
        },
        onRemove: () => {
            PostManagementRequests.updatePost(
                post.publisher_type == 'user' ? 'users': 'businesses',
                {id: post.publisher_id} as PublisherEndpointModel, post,{
                removed_at: moment().toISOString(),
            }).then(onPostRemoved)
        },
        onReport: () => {
            if (!postResponse.reported) {
                updatePostResponse({
                    ...postResponse,
                    reported: true,
                })
            }
        },
    }

    const commentingInteractions: PostCommentInteractions = {
        onCommentCountChanged: (newCount: number) => {
            onPostMetaChanged({
                ...post,
                comment_count: newCount,
            })
        },
        participant: commenting?.participant,
        createMessage: commenting?.createMessage,
        createPostThread: commenting?.createPostThread,
    };

    useEffect(() => {
        setOriginalPost({...post});
    }, [post.id]);

    useEffect(() => {
        if (postResponseContext.postResponse) {
            setSearchCompleted(true);
            let restoredPostResponse = postResponse;
            if (postResponseContext.postResponse.id) {
                restoredPostResponse = mergePostResponses(postResponseContext.postResponse, restoredPostResponse)
            }
            uploadPostResponse({...restoredPostResponse})

        }
    }, [postResponseContext.postResponse]);

    useEffect(() => {
        if (hasViewed) {
            updatePostResponse({
                ...postResponse,
                dismissed: true,
            })
        }
    }, [hasViewed])

    return (
        <React.Fragment>
            <PostDetails
                post={post}
                interactions={loggedInUser && actions}
                commenting={commentingInteractions}
                onLikeClicked={onLikeClicked}
                postResponse={postResponse}
                userFollowsContext={userFollowsContext}
                location={location}
                {...rest}
            />
            <AlbumModal
                isOpen={saveModalShowing}
                onDidDismiss={() => setSaveModalShowing(false)}
                onPostSave={actions.onSaved}
                showAddAlbumOnly={false}
                post={originalPost}
            />
            <IonToast
                isOpen={toastMessage.length > 0}
                duration={2000}
                onDidDismiss={() => setToastMessage("")}
                message={toastMessage}
            />
        </React.Fragment>
    )
}

export interface InteractivePostOwnProps {
    post: Post,
    onPostMetaChanged: (post: Post) => void,
    hasViewed: boolean,
    inFocus: boolean,
    locationPageUrlRoot: string,
    loggedInUser: User,
    onPostResponseChanged?: (postResponse: PostResponse) => void,
    userPageUrlRoot?: string,
    location?: Location,
    commenting?: {
        participant: CanParticipateInForum,
        createPostThread: (post: Post, topic: string) => Promise<Thread>,
        createMessage: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
    }
    onPostRemoved: () => void,
    onUnfollow?: () => void,
    onLikeClicked?: () => void,
    userFollowsContext?: UserFollowsContextState,
}

interface StateProps {
    sharedPostInit: boolean,
    notificationPostInit: boolean,
}

interface DispatchProps {
    setSharedPostInit: typeof setSharedPostInit,
    setNotificationPostInit: typeof setNotificationPostInit,
}

interface InteractivePostProps extends InteractivePostOwnProps, StateProps, DispatchProps {}


const InteractivePost: React.FC<InteractivePostProps> = ({loggedInUser, post, ...props}) => {

    return (
        <UserCollectionsContextProvider userId={loggedInUser.id!}>
            <UserCollectionsContext.Consumer>
                {userCollectionsContext =>
                    <CollectionItemsContextProvider>
                        <CollectionItemsContext.Consumer>
                            {collectionItemsContext =>
                                <PostResponseContextProvider userId={loggedInUser.id!} postId={post.id!}>
                                    <PostResponseContext.Consumer>
                                        {postResponseContext =>
                                            <InteractivePostLoaded
                                                post={post}
                                                postResponseContext={postResponseContext}
                                                loggedInUser={loggedInUser}
                                                userCollectionsContext={userCollectionsContext}
                                                collectionItemsContextState={collectionItemsContext}
                                                {...props}
                                            />
                                        }
                                    </PostResponseContext.Consumer>
                                </PostResponseContextProvider>
                            }
                        </CollectionItemsContext.Consumer>
                    </CollectionItemsContextProvider>
                }
            </UserCollectionsContext.Consumer>
        </UserCollectionsContextProvider>
    )
}

export default connect<InteractivePostOwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
        sharedPostInit: state.session.sharedPostInit,
        notificationPostInit: state.session.notificationPostInit,
    }),
    mapDispatchToProps: ({
        setSharedPostInit,
        setNotificationPostInit
    }),
    component: InteractivePost
});
