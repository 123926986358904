import Post from '../../../models/post/post';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import './index.scss';
import {
    IonContent, IonIcon
} from '@ionic/react';
import Location from '../../../models/location/location';
import PostResponse from '../../../models/post/post-response';
import {PostCommentInteractions} from '../PostComments';
import PostPublishedDetails from '../PostPublishedDetails';
import PostTextContent from '../PostTextContent';
import PostActions, {PostInteractions} from '../PostActions';
import PostMetaInformation from '../PostMetaInformation';
import {UserFollowsContextState} from '../../../contexts/UserFollowsContext';

interface PostDetailsProps {
    post: Post,
    postResponse?: PostResponse,
    locationPageUrlRoot: string,
    userPageUrlRoot?: string,
    isPreview?: boolean,
    location?: Location,
    interactions?: PostInteractions,
    commenting?: PostCommentInteractions,
    onLikeClicked?: () => void,
    onOptionsClickedOverride?: () => void,
    inFocus?: boolean,
    userFollowsContext?: UserFollowsContextState,
}

const PostDetails: React.FC<PostDetailsProps> = ({post, postResponse, isPreview, location, locationPageUrlRoot, userPageUrlRoot, interactions, commenting, onLikeClicked, onOptionsClickedOverride, inFocus, userFollowsContext}) => {

    const video = useRef<HTMLVideoElement>(null);
    const [canPlay, setCanPlay] = useState(false);
    const [videoLoadStarted, setVideoLoadStarted] = useState(false);
    const [cachedLocation, setCachedLocation] = useState(location);

    useEffect(() => {
        if (location) {
            setCachedLocation(location)
        }
    }, [location]);

    useEffect(() => {
        if (video.current) {

            if (!videoLoadStarted) {
                video.current.load();
                setVideoLoadStarted(true);
            }
            setCanPlay(video.current.readyState > 3);
            video.current.addEventListener('canplay', () => {
                setCanPlay(true);
            })
        }
    }, [video.current])

    useEffect(() => {
        if (video.current) {
            if (canPlay && inFocus) {
                setTimeout(() => {
                    if (video.current) {
                        video.current.play().catch(console.error);
                    }
                }, 10)
            } else {
                video.current.pause();
            }
        }
    }, [canPlay, inFocus])

    return (
        <div className={'post-wrapper ' + (post.publisher_type === 'user' ? 'user-post' : '')}>
            <PostMetaInformation post={post}/>
            <div className={'post-details'}>
                <PostPublishedDetails
                    post={post}
                    isPreview={isPreview}
                    location={cachedLocation}
                    locationPageUrlRoot={locationPageUrlRoot}
                    userPageUrlRoot={userPageUrlRoot}
                    onFollow={interactions ? interactions.onFollow : undefined}
                    onUnfollow={interactions ? interactions.onUnfollow : undefined}
                    userFollowsContext={userFollowsContext}
                />
                <div className={'content-wrapper'}>
                    {post.video_url ?
                        <video
                            className={'post-video'}
                            ref={video}
                            autoPlay={false}
                            loop
                            playsInline={true}
                            poster={post.main_image_url}
                            preload={'auto'}
                            controls
                            muted
                        >
                            <source src={post.video_url} type="video/mp4"/>
                        </video> :
                        <img
                            className={'main-post-image'}
                            src={post.main_image_url}
                        />
                    }
                </div>
                <PostTextContent post={post}/>
                <PostActions
                    post={post}
                    postResponse={postResponse}
                    location={cachedLocation}
                    interactions={interactions}
                    commenting={commenting}
                    onLikeClicked={onLikeClicked}
                    onOptionsClickedOverride={onOptionsClickedOverride}
                />
            </div>
        </div>
    )
}

export default PostDetails;
