import {
    IonButton,
    IonContent,
    IonInput,
    IonLabel,
    IonPage,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import React, {PropsWithChildren, useState} from 'react';
import './index.scss';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import BusinessCreationHeader from '../../../components/BusinessCreationHeader';
import GrayInput from '../../../components/GeneralUIElements/GrayInput';
import IndustrySelector from '../../../components/IndustrySelector';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import {connect} from '../../../data/connect';
import {setManagingBusinessId} from '../../../data/persistent/persistent.actions';
import User, {canUserCreateBusiness} from '../../../models/user/user';
import MembershipPlan, {findDefaultMembershipPlan} from '../../../models/subscription/membership-plan';
import Category from '../../../models/category';
import {AvailableRoles} from '../../../models/role';
import {useHistory, useLocation} from 'react-router';

import {MembershipPlansContextProvider, MembershipPlansContext} from '../../../contexts/MembershipPlansContext';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import {addHttpPrefix} from "../../../util/strings";
import {RequestError} from '../../../models/request-error';
import ServerAlert from '../../../components/ServerAlert';

interface ErrorProps{
    error?: string
    touched?: boolean
}

const ErrorMessage: React.FC<PropsWithChildren<ErrorProps>> = ({error, touched, children}) => (
    error && touched ? <p className={'error'}>{error}</p> : null
)

interface BusinessCreationData {
    name: string,
    main_category: Category,
    website: string
}

const initialBusinessCreationState: BusinessCreationData = {
    name: '',
    main_category: {
        name: '',
    } as Category,
    website: ''
};

interface BusinessCreationFormProps {
    saving: boolean,
    submit: (data: BusinessCreationData) => void,
    noun: 'Business' | 'Organization',
}

const BusinessCreationForm: React.FC<BusinessCreationFormProps> = ({submit, noun, saving}) => {

    const BusinessCreationSchema = Yup.object().shape({
        name: Yup.string().trim().required(noun + ' Name is required'),
        main_category: Yup.object().shape({
            name: Yup.string().trim().required(noun + ' Industry is required')
        }),
        website: Yup.string().trim().required(noun + ' Website is required'),
    });

    const form = useFormik({
        initialValues: initialBusinessCreationState,
        validationSchema: BusinessCreationSchema,
        onSubmit: (values) => submit(values)
    });

    const onIndustryChange = (industry: Category) => form.setFieldValue( 'main_category', industry )

    return (
        <IonContent>
            <IonLabel>{noun} Name</IonLabel>
            <GrayInput>
                <IonInput
                    autofocus={true}
                    name='name'
                    type='text'
                    autocapitalize={'word'}
                    onIonChange={form.handleChange}
                />
            </GrayInput>
            <p className={'footnote'}>If your {noun.toLowerCase()} has multiple locations with unique names, enter the name of your '{noun.toLowerCase()} group'</p>
            <ErrorMessage error={form.errors.name} touched={form.touched.name}/>
            <IonLabel>{noun} Industry</IonLabel>
            <IndustrySelector name={'main_category_id'} placeholder={'Choose Industry'} onChange={onIndustryChange} />
            <ErrorMessage error={form.errors.main_category ? form.errors.main_category.name : ''} touched={form.touched.main_category ? form.touched.main_category.name : false}/>
            <IonLabel>{noun} Website</IonLabel>
            <GrayInput>
                <IonInput
                    name='website'
                    type='url'
                    placeholder={'https://'}
                    onIonChange={form.handleChange}
                />
            </GrayInput>
            <ErrorMessage error={form.errors.website} touched={form.touched.website}/>
            <IonButton disabled={saving} onClick={() => form.handleSubmit()}>
                Next
            </IonButton>
        </IonContent>
    );
}

interface BusinessInformationFormProps {
    setMe: (me: User) => void,
    me: User,
    setManagingBusinessId: (businessId: number) => void,
    membershipPlans: MembershipPlan[],
}

const BusinessInformationForm: React.FC<BusinessInformationFormProps> = ({me, setMe, membershipPlans, setManagingBusinessId}) => {

    const location = useLocation();
    const noun = location.pathname == 'business-information' ? 'Business' : 'Organization';

    const history = useHistory();
    const trialPlan = membershipPlans.find(membershipPlan => membershipPlan.trial_period);
    const freePlan = findDefaultMembershipPlan(membershipPlans);
    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);
    const [saving, setSaving] = useState(false);

    const submit = async (businessData: BusinessCreationData) => {

        try {
            setSaving(true);
            const organization = await OrganizationRequests.createOrganization({name: businessData.name})

            const website = addHttpPrefix(businessData.website)
            const business = await OrganizationRequests.createBusiness(organization?.id!, {
                name: businessData.name,
                main_category_id: businessData.main_category.id,
                website: website
            })
            business.main_category = businessData.main_category;
            organization.businesses = [business];

            if (trialPlan) {
                await OrganizationRequests.createSubscription(organization?.id!, {
                    membership_plan_rate_id: trialPlan.current_rate_id,
                    is_trial: true,
                    location_count: 1,
                })
            }

            me.organization_managers?.push({
                organization: organization,
                user: me,
                user_id: me.id!,
                organization_id: organization.id!,
                role_id: AvailableRoles.Administrator,
            });
            setMe({...me})

            setManagingBusinessId(business.id!)
            history.push('/organization-creator/' + noun.toLowerCase() + '-created/' + ( canUserCreateBusiness(me) ? 'beta' : 'pending' ))
        } catch(error) {
            setRequestError(error as RequestError)
        }
        setSaving(false);
    }

    return (
        <React.Fragment>
            <BusinessCreationForm
                noun={noun}
                submit={submit}
                saving={saving}
            />
            {requestError &&
                <ServerAlert
                    requestError={requestError}
                    onCloseAlert={() => setRequestError(undefined)}
                />
            }
        </React.Fragment>
    )
}

interface DispatchProps {
    setManagingBusinessId: typeof setManagingBusinessId,
}

interface BusinessInformationProps extends DispatchProps {
}

const BusinessInformation: React.FC<BusinessInformationProps> = ({setManagingBusinessId}) => {

    return (
        <IonPage id={'business-information-form'}>
            <BusinessCreationHeader/>
            <IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <MembershipPlansContextProvider>
                                <MembershipPlansContext.Consumer>
                                    {membershipPlanContext => (
                                        <BusinessInformationForm
                                            setManagingBusinessId={setManagingBusinessId}
                                            me={meContext.me}
                                            setMe={meContext.setMe}
                                            membershipPlans={membershipPlanContext.loadedData}
                                        />
                                    )}
                                </MembershipPlansContext.Consumer>
                            </MembershipPlansContextProvider>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default connect<{ }, { }, DispatchProps >({
    mapDispatchToProps: ({
        setManagingBusinessId,
    }),
    component: BusinessInformation
});
