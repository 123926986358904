import React  from 'react';
import {IonPage, IonRouterOutlet} from '@ionic/react';
import {Route, useLocation} from 'react-router';
import SignInPhone from './SignIn/SignInPhone';
import ForgotPassword from './ForgotPassword';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import AddEmail from './SignUp/EmailStep'
import NameAndPhoneStep from './SignUp/NameAndPhoneStep'

import './index.scss';
import ContactUs from './ContactUs';
import VerificationCodeStep from './SignUp/VerificationCodeStep';
import VerificationCode from './SignIn/VerificationCode';
import NotificationsPrompt from './NotificationsPrompt';
import InvitationCodeStep from './SignUp/InvitationCodeStep';
import {Redirect} from 'react-router-dom';
import WelcomeTemplate from '../../components/WelcomeSection/WelcomeTemplate';
import Post from './Post';
import BusinessLocation from './BusinessLocation';
import Splash from './Splash';

const Welcome: React.FC = () => {

    return (
        <WelcomeTemplate>
            <IonRouterOutlet id={'welcome-section'}>
                <Redirect exact path={'/welcome'} to={`/welcome/splash`}/>
                <Route exact path={`/welcome/notifications`} render={() => <NotificationsPrompt/>} />
                <Redirect exact path={'/welcome/sign-in'} to={`/welcome/sign-in/phone`}/>
                <Route exact path={`/welcome/sign-in/phone`} render={() => <SignInPhone/>} />
                <Route exact path={`/welcome/sign-in/verification-code`} render={() => <VerificationCode/>} />
                <Route exact path={`/welcome/sign-up/invitation-code`} render={() => <InvitationCodeStep/>} />
                <Route exact path={`/welcome/sign-up/invitation-code/:token`} render={() => <InvitationCodeStep/>} />
                <Route exact path={`/welcome/sign-up/name-phone`} render={() => <NameAndPhoneStep/>} />
                <Route exact path={`/welcome/sign-up/verification-code`} render={() => <VerificationCodeStep/>} />
                <Route exact path={`/welcome/sign-up/email`} render={() => <AddEmail/>} />
                <Redirect exact path={'/welcome/sign-up'} to={`/welcome/sign-up/name-phone`}/>
                <Route exact path={`/welcome/forgot-password`} render={() => <ForgotPassword/>} />
                <Route exact path={`/welcome/privacy-policy`} render={() => <PrivacyPolicy/>} />
                <Route exact path={`/welcome/terms`} render={() => <TermsOfUse/>} />
                <Route exact path={`/welcome/contact-us`} render={() => <ContactUs/>} />
                <Redirect exact path={'/welcome/splash'} to={`/welcome/splash/home`}/>
                <Route exact path={`/welcome/splash/:section`} component={Splash} />
                <Route exact path={`/welcome/post/:postId`} render={() => <Post/>} />
                <Route exact path={`/welcome/business/:businessId/location/:locationId`} render={() => <BusinessLocation/>} />
            </IonRouterOutlet>
        </WelcomeTemplate>
    );
};

export default Welcome;
