import React from 'react';
import './index.scss';
import './responsive.scss';
import AccountSwitcher from "../../AccountSwitcher";
import MeContextProvider, { MeContext, MeContextStateConsumer } from '../../../contexts/MeContext';
import {IonContent, IonMenu} from "@ionic/react";


interface HomeDesktopMenuProps {
}

const HomeDesktopMenuRight: React.FC<HomeDesktopMenuProps> = () => {

    return (

        <IonMenu className={'home-desktop-menu end'} contentId={'home'} side={"end"} swipeGesture={false}>
            <IonContent>
                <MeContextProvider hideLoadingSpace={true}>
                    <MeContext.Consumer>
                        {(meContext: MeContextStateConsumer) => <AccountSwitcher selectedText={meContext.me.full_name} />}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonMenu>

    );
};

export default HomeDesktopMenuRight;
