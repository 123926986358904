import React from 'react';
import './index.scss';
import {IonButton, IonButtons, IonHeader, IonToolbar} from '@ionic/react';
import {useHistory} from 'react-router';


const WelcomeHeader: React.FC = () => {

	const history = useHistory();

	return (
		<IonHeader mode={'ios'} className={'welcome-header hide-in-mobile'}>
			<IonToolbar>
				<IonButtons slot={'start'}>
					<img
						className='logo hide-in-mobile'
						src='/assets/web-splash/geddit-local-logo.svg'
						alt="Geddit Local"
						onClick={() => history.push('/welcome/splash/home')}
					/>
				</IonButtons>
				<IonButtons slot={'end'} className={'hide-in-mobile'}>
					<IonButton className={'btn-sign-up'} routerLink={'/welcome/sign-up/invitation-code'}>
						Sign Up
					</IonButton>
					<IonButton routerLink={'/welcome/sign-in/phone'}>Sign In</IonButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
	);
}

export default WelcomeHeader
