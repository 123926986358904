import React from 'react';
import ReactDOM from 'react-dom';
import packageJson from '../package.json';
import { init as sentryInit, browserTracingIntegration } from '@sentry/capacitor';
import { init as sentryReactInit } from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

sentryInit(
    {
        dsn: "https://a7f0e9b77d5fd851a38aa0c6914d017f@o4508718255636480.ingest.us.sentry.io/4508718259961856",
        integrations: [
            browserTracingIntegration(),
        ],
        release: packageJson.version,
        // Tracing
        tracePropagationTargets: ['localhost', /^https:\/\/(qa-)?api\.gedditlocal\.com/],
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
    },
    // Forward the init method from @sentry/react
    sentryReactInit
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
